(function () {
    angular.module('informaApp')
        .component('verticalNavigationPanel', {
            templateUrl: 'components/summary-view/vertical-navigation-panel/template.html',
            controller: VerticalNavigationPanel,
            bindings: {
                callbacks: '<',
                hiddenItems: '<'
            }
        });

    function VerticalNavigationPanel($element) {
        this.$onInit = () => {
            const items = $element.find('.vertical-navigation-panel-item');
            const firstItem = items.first();
            const parent = $element.parent();

            window.addEventListener('scroll', () => toggleNavigationPanelMode(items, firstItem, parent));

            bindMouseEvent(items, parent);
        };
    }

    function bindMouseEvent(items, parent) {
        const firstItem = items.first();

        const onEnter = generateMoveEvent(parent, x => x.innerWidth(), firstItem);
        items.on('mouseover', onEnter);

        const onLeave = generateMoveEvent(parent, getIconWidth, firstItem);
        items.on('mouseleave', onLeave);

        items.find('img, span').on('mouseleave', (event) => {
            const parentPanel = event.target.closest('.vertical-navigation-panel-item');

            if (event.relatedTarget !== parentPanel) {
                onLeave(_.merge({}, event, {target: parentPanel}))
            }
        });
    }

    function generateMoveEvent(parent, getOffsetX, firstItem) {
        const parentLeftPadding = 15;
        const animationDuration = 500;

        return (event) => {
            const element = $(event.target);
            element.stop(true);

            const offsetX = getOffsetX(element);

            const destinationX = isElementOutOfScreen(firstItem)
                ? parent.offset().left - offsetX
                : parentLeftPadding - offsetX;

            element.animate({left: destinationX}, animationDuration, 'easeOutQuint');
        };
    }

    function toggleNavigationPanelMode(items, firstItem, parent) {
        if (firstItem.css('position') !== 'absolute' && isElementAbove(firstItem, parent)) {
            items.removeClass('fixed-top');

            items.finish();

            items.css('left', '');
        }

        if (firstItem.css('position') !== 'fixed' && isElementOutOfScreen(firstItem)) {
            items.addClass('fixed-top');

            items.finish();

            items.css('left', parent.offset().left - getIconWidth(firstItem));
        }
    }

    function isElementAbove(element, target) {
        return element.offset().top <= target.offset().top;
    }

    function isElementOutOfScreen(element) {
        return element.offset().top <= window.scrollY;
    }

    function getIconWidth(panelItem) {
        return panelItem.find('.panel-icon').outerWidth(true);
    }
})();